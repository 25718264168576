@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("quicksand.6714060b.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: #fff0;
  -webkit-tap-highlight-color: transparent;
  box-sizing: inherit;
}

:before, :after {
  box-sizing: inherit;
}

input[type], [contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

body, h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
}

base, basefont, datalist, head, meta, script, style, title, noembed, param, template {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

b {
  font-weight: 400;
}

em, i {
  font-style: normal;
}

a:focus, button:focus {
  outline: 0;
}

button {
  -webkit-appearance: none;
  appearance: none;
  text-align: inherit;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
}

input, fieldset {
  -webkit-appearance: none;
  appearance: none;
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 1rem;
}

input::-ms-clear {
  display: none;
}

input:focus {
  outline: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

svg {
  display: block;
}

img {
  max-width: 100%;
  display: block;
}

select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 #000;
}

button::-moz-focus-inner {
  padding: inherit;
}

body {
  box-sizing: border-box;
  font-family: Quicksand, sans-serif;
}

html, body {
  overscroll-behavior-y: contain;
  overscroll-behavior-x: contain;
}

:root {
  --primary: 0, 123, 255;
  --on-primary: #fff;
  --secondary: 151, 45, 225;
  --on-secondary: #fff;
  --error: 245, 54, 92;
  --on-error: #fff;
  --black: #323232;
  --padding: 30px;
  --border-radius: 26px;
  --shadow: 0 2px 5px 0 #19191912, 0 3px 4px -2px #1919191a, 0 1px 8px 0 #1919190f;
  --shadow-hover: 0 10px 20px -12px #3232326b, 0 4px 20px 0px #3232321f, 0 8px 10px -5px #32323233;
}

/*# sourceMappingURL=index.aeb22038.css.map */
